import { Link } from 'gatsby'
import React, { FC, useState, useEffect } from 'react'
import siteData from '../../_data/site.json'

import Logo from '../../images/black-elephant-logo.svg'
import LogoSymbol from '../../images/be-symbol.svg'
import MobileMenu from '../../images/mobile-toggler.svg'

interface HeaderT {}

const Header: FC<HeaderT> = () => {
  const { main } = siteData.navigation
  const [isMobileOpen, setIsMobileOpen] = useState<boolean>(false)

  const toggleMobileMenu = () => {
    setIsMobileOpen(!isMobileOpen)
  }

  useEffect(() => {
    const watchMobileMenu = () => {
      if (window.innerWidth > 768) {
        setIsMobileOpen(false)
      }
    }
    window.addEventListener('resize', watchMobileMenu)

    return () => {
      window.removeEventListener('resize', watchMobileMenu)
    }
  }, [])

  return (
    <>
      <div className="ribbon">
        <div className="ribbon-col"></div>
        <div className="ribbon-col"></div>
        <div className="ribbon-col"></div>
        <div className="ribbon-col"></div>
        <div className="ribbon-col"></div>
        <div className="ribbon-col"></div>
        <div className="ribbon-col"></div>
        <div className="ribbon-col"></div>
      </div>
      <header className="header">
        <div className="container">
          <Link className="logo-container" to="/">
            <Logo className="logo" />
          </Link>
        </div>
        <div className="container">
          <Link className="mobile-home-link" to="/">
            <LogoSymbol className="logo-symbol" />
          </Link>
          <nav className={`nav`}>
            {main.map((item: { url: string; label: string }, index: number) => {
              if (
                item.label === 'Privacy Policy' ||
                item.label === 'Legal Terms'
              )
                return
              return (
                <Link
                  className="nav__link"
                  activeClassName="nav__link--current"
                  partiallyActive={index !== 0}
                  key={index}
                  to={`${item.url}`}
                  state={{
                    current:
                      item.url === '/our-services'
                        ? 'Workplace Strategy'
                        : item.label,
                  }}
                >
                  {item.label}
                </Link>
              )
            })}
          </nav>
          <button
            className="btn btn--icon-only mobile-menu-toggler"
            onClick={toggleMobileMenu}
          >
            <MobileMenu className="btn__icon" />
          </button>
        </div>
        <nav className={`nav--mobile ${isMobileOpen ? 'nav--isOpen' : ''}`}>
          {main.map((item: { url: string; label: string }, index: number) => {
            if (item.label === 'Privacy Policy') return
            return (
              <Link
                className="nav__link"
                activeClassName="nav__link--current"
                partiallyActive={index !== 0}
                key={index}
                to={item.url}
              >
                {item.label}
              </Link>
            )
          })}
        </nav>
      </header>
    </>
  )
}

export default Header
