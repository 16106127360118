import React, { FC, useState, useEffect } from 'react'
import siteData from '../../_data/site.json'
import { Link } from 'gatsby'
import Clock from '../clock'

import Logo from '../../images/black-elephant-logo.svg'
// import Clock from '../../images/clock.svg'
import FB from '../../images/fb.svg'
import IG from '../../images/ig.svg'
import LN from '../../images/ln.svg'
import BE from '../../images/be.svg'

interface FooterT {}

const Footer: FC<FooterT> = () => {
  const { main } = siteData.navigation
  return (
    <footer className="footer">
      <div className="container">
        <Logo className="logo" />
      </div>
      <div className="container">
        <nav className="nav">
          <div className="lets-connect">
            <h5 className="nav-title">Let's Chat</h5>
            <p>We would love to discuss your project aspirations.</p>
            <Link to="/contact" className="link link--btn">
              Contact
            </Link>
            <div className="social-links">
              <a
                href="https://www.facebook.com/blackelephant.design"
                target="_blank"
                rel="nopener noreferrer"
                className="social-links__link"
              >
                <FB className="social-links__icon" />
              </a>
              <a
                href="https://www.instagram.com/beidstudio/"
                target="_blank"
                rel="nopener noreferrer"
                className="social-links__link"
              >
                <IG className="social-links__icon" />
              </a>
              <a
                href="https://www.linkedin.com/in/mae-teh-a44042137/"
                target="_blank"
                rel="nopener noreferrer"
                className="social-links__link"
              >
                <LN className="social-links__icon" />
              </a>
              <a
                href="https://www.behance.net/meiteh"
                target="_blank"
                rel="nopener noreferrer"
                className="social-links__link"
              >
                <BE className="social-links__icon" />
              </a>
            </div>
          </div>
          <div className="pages">
            <h5 className="nav-title">Pages</h5>
            <div className="pages-links">
              {main.map(
                (item: { url: string; label: string }, index: number) => {
                  return (
                    <Link
                      className="pages-links__link"
                      key={index}
                      to={item.url}
                    >
                      {item.label}
                    </Link>
                  )
                },
              )}
            </div>
          </div>
          <div className="contact">
            <h5 className="nav-title">Contact</h5>
            <address className="address">
              31 Friern Barnet Road
              <br />
              London, N11 1NE
              <br />
              P: 020 8368 0147
              <br />
              E:{' '}
              <a href="mailto:studio@blackelephant.design?subject=Your%20Enquiry">
                studio@blackelephant.design
              </a>
            </address>
          </div>
          <div className="schedule">
            <h5 className="nav-title">Schedule</h5>
            <div className="schedule__clock">
              {/* <Clock className="clock-icon" /> */}
              <Clock />
            </div>
          </div>
        </nav>
        <div className="copyright">
          {new Date().getFullYear()} &copy; black elephant. Branded by{' '}
          <a href="https://holy.gd">høly</a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
