/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'

import Header from './header'
import Footer from './footer'
import { Link } from 'gatsby'

import '../styles/index.scss'

const Layout = ({ children, breadcrumbs = [] }) => {
  return (
    <>
      <Header />
      {breadcrumbs.length ? (
        <div className="container breadcrumb-container">
          {breadcrumbs.map((breadcrumb, index) => (
            <div
              style={{
                color: breadcrumb.color,
              }}
              key={breadcrumb.name}
            >
              {breadcrumb.to ? (
                <Link to={breadcrumb.to}>{breadcrumb.name}</Link>
              ) : (
                breadcrumb.name
              )}
              {index < breadcrumbs.length - 1 && (
                <span style={{ margin: '0 0.5rem' }}>/</span>
              )}
            </div>
          ))}
        </div>
      ) : null}
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object,
  crumbLabel: PropTypes.string,
}

export default Layout
