import React, {
  FC,
  useEffect,
  useRef,
  CSSProperties,
  useState,
  cloneElement,
} from 'react'
import ClockBG from '../../images/clock-bg.svg'

const Clock: FC = () => {
  const hourHand = useRef<HTMLDivElement>(null)
  const minHand = useRef<HTMLDivElement>(null)
  const [backgroundColor, setBackgroundColor] = useState<string>(
    `ribbon-color-8`,
  )
  // const [open, setOpen] = useState<string>('NOW OPEN')
  const [open, setOpen] = useState<{
    open: boolean
    now: string
    till: string
  }>({
    open: true,
    now: 'NOW OPEN',
    till: 'until 18:00',
  })

  useEffect(() => {
    const dateInt = setInterval(setDate, 1000)
    setDate()

    return () => {
      clearInterval(dateInt)
    }
  }, [hourHand, minHand])

  const setDate = () => {
    const timeZone = 'Europe/London'

    const nowSec = parseInt(
      new Date().toLocaleString('en-GB', {
        second: '2-digit',
        timeZone: timeZone,
      }),
    )

    const nowMin = parseInt(
      new Date().toLocaleString('en-GB', {
        minute: '2-digit',
        timeZone: timeZone,
      }),
    )

    const nowHour = parseInt(
      new Date().toLocaleString('en-GB', {
        hour: '2-digit',
        hour12: false,
        timeZone: timeZone,
      }),
    )

    const nowDay = new Date().toLocaleString('en-GB', {
      weekday: 'short',
      timeZone: timeZone,
    })

    if (nowHour >= 18 || nowHour < 9 || nowDay === 'Sat' || nowDay === 'Sun') {
      const till =
        nowHour < 18
          ? 'until 18:00'
          : /Fri|Sat|Sun/g.test(nowDay)
          ? 'Opens Monday 9:00 AM'
          : 'Opens Tomorrow 9:00 AM'

      setOpen({ ...open, open: false, now: 'NOW CLOSED', till: till })
      setBackgroundColor('ribbon-color-1')
    } else {
      nowHour === 9 && setBackgroundColor('ribbon-color-8')
      nowHour === 10 && setBackgroundColor('ribbon-color-8')
      nowHour === 11 && setBackgroundColor('ribbon-color-8')
      nowHour === 12 && setBackgroundColor('ribbon-color-7')
      nowHour === 13 && setBackgroundColor('ribbon-color-6')
      nowHour === 14 && setBackgroundColor('ribbon-color-5')
      nowHour === 15 && setBackgroundColor('ribbon-color-4')
      nowHour === 16 && setBackgroundColor('ribbon-color-3')
      nowHour === 17 && setBackgroundColor('ribbon-color-2')

      const till =
        nowHour < 18
          ? 'until 18:00'
          : /Fri|Sat|Sun/g.test(nowDay)
          ? 'Opens Monday 9:00 AM'
          : 'Opens Tomorrow 9:00 AM'

      setOpen({ ...open, open: true, now: 'NOW OPEN', till: till })
    }

    const minsDegrees = (nowMin / 60) * 360 + (nowSec / 60) * 6 + 90
    minHand.current.style.transform = `rotate(${minsDegrees}deg)`

    const hourDegrees = (nowHour / 12) * 360 + (nowMin / 60) * 30 + 90
    hourHand.current.style.transform = `rotate(${hourDegrees}deg)`
  }

  return (
    <>
      <div
        className="clock"
        style={
          {
            '--background-color': `var(--${backgroundColor})`,
          } as CSSProperties
        }
      >
        <ClockBG className="clock__background" />
        <div className="clock__face">
          <div className="clock__hand clock__hand--hour" ref={hourHand}></div>
          <div className="clock__hand clock__hand--min" ref={minHand}></div>
          <div className="clock__center" />
        </div>
      </div>
      <div className="schedule__timetable">
        <div className="state">{open.now}</div>
        <div className="till">{open.till}</div>
      </div>
    </>
  )
}

export default Clock
